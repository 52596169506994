<template>
  <div>
    <Input
      label="Adı Soyadı *"
      v-model="form.fullName"
      :defaultValue="selectedItem.fullName"
      :state="validateState('fullName')"
    />
    <Input
      label="Email *"
      v-model="form.email"
      :defaultValue="selectedItem.email"
      :state="validateState('email')"
    />
    <div class="row">
      <div class="col-6">
        <Input
          label="Telefon"
          v-model="form.phone"
          :defaultValue="selectedItem.phone"
        />
      </div>
      <div class="col-6">
        <Checkbox
          formGroupLabel="Durum *"
          label="Aktif"
          v-model="form.isActive"
          :defaultValue="
            selectedItem.isActive === true || selectedItem.isActive === false
              ? selectedItem.isActive.toString()
              : ''
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'InsertUpdateOperations',
  mixins: [validationMixin],
  props: {
    confirmModal: Boolean,
    selectedItem: String | Object,
  },
  data() {
    return {
      loading: false,
      form: {
        fullName: '',
        email: '',
        phone: '',
        isActive: true,
      },
    };
  },
  validations: {
    form: {
      fullName: {
        required,
      },
      email: {
        required,
        email,
      },
      isActive: {
        required,
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    confirm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$emit('resetInsertUpdateForm', false);
        return;
      }
      let payload = {
        userType: 'SystemAdministrator',
        fullName: this.form.fullName,
        email: this.form.email,
        isActive: this.form.isActive,
      };

      if (this.form.phone) payload.phone = this.form.phone;

      this.$dbFunctions
        .insertOrUpdate(`account`, this.selectedItem.id, payload)
        .then((res) => {
          this.$dbFunctions.setResponse(this, res);
          this.$emit('resetInsertUpdateForm', true);
          this.formReset();
        })
        .catch((err) => {
          this.$dbFunctions.setResponse(this, err);
          this.$emit('resetInsertUpdateForm', false);
        })
        .finally(() => {});
    },
    formReset() {
      Object.keys(this.form).forEach((key) => (this.form[key] = ''));
      this.$v.$reset();
    },
  },
  watch: {
    confirmModal(newVal) {
      if (newVal) {
        this.confirm();
      }
    },
    selectedItem(newVal) {
      this.formReset();
    },
  },
};
</script>
